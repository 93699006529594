import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import Messages from './Messages';
import Status from './Status';
import Header from './Header';

import { useContentComponent } from './utils/useContentComponent';
import classes from './styles.module.scss';

export default function JobApplicationInfo({
  isVisible,
  hideJobsApplicationInfo,
  currentJobApplication,
  additionalDetails,
  reloadMessageCounters,
  refetch,
  connection,
  initialMode,
}) {
  const [mode, setMode] = useState('jobApplicationProgress');

  const [hasMessages, setHasMessages] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  useEffect(() => {
    if (additionalDetails?.messageCounter) {
      setHasMessages(additionalDetails.messageCounter.totalCount > 0);
      setHasUnreadMessages(additionalDetails.messageCounter.unreadCount > 0);
    } else {
      setHasMessages(false);
      setHasUnreadMessages(false);
    }
  }, [additionalDetails]);

  useEffect(() => {
    if (initialMode) {
      setMode(initialMode);
    }
  }, [initialMode]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [isVisible]);

  const toggleMode = () => {
    setMode((currentMode) => {
      if (currentMode === 'jobApplicationProgress') {
        return 'messages';
      }
      return 'jobApplicationProgress';
    });
  };

  const {
    contentComponent: ContentComponent,
    contentComponentProps,
    closeJobApplicationInfo,
  } = useContentComponent(
    currentJobApplication,
    refetch,
    hideJobsApplicationInfo
  );

  let mainContent = null;

  if (ContentComponent && mode === 'jobApplicationProgress') {
    mainContent = <ContentComponent {...contentComponentProps} />;
  } else if (mode === 'messages') {
    mainContent = (
      <Messages
        goBack={toggleMode}
        refetch={refetch}
        connection={connection}
        reloadMessageCounters={reloadMessageCounters}
        jobApplicationId={currentJobApplication?.id}
        fromNotification={initialMode === 'messages'}
      />
    );
  }

  const isJobClosed =
    currentJobApplication?.jobPost.status === 'UnPublished' ||
    currentJobApplication?.subStatus === 'JobClosed';

  return (
    <>
      {isVisible && <div className={classes.backdrop} />}
      <div
        className={classNames(classes.JobApplicationInfo, {
          [classes.visible]: isVisible,
        })}
      >
        <div className={classes.headerContainer}>
          <Header
            hideJobsApplicationInfo={closeJobApplicationInfo}
            title={currentJobApplication?.jobPost.jobTitle}
            location={currentJobApplication?.location?.city?.name}
            jobId={currentJobApplication?.jobPost.id}
            toggleMode={toggleMode}
            hasMessages={hasMessages}
            hasUnreadMessages={hasUnreadMessages}
          />
        </div>
        {!isJobClosed && (
          <div className={classes.statusContainer}>
            <Status
              currentJobApplication={currentJobApplication}
              assessment={currentJobApplication?.jobApplicationAssessments[0]}
              status={currentJobApplication?.status}
              subStatus={currentJobApplication?.subStatus}
              hasAssessment={
                !!currentJobApplication?.jobPost?.assignedAssessments?.length ||
                !!currentJobApplication?.assignedAssessments?.length
              }
            />
          </div>
        )}
        <div className={classes.content}>{mainContent}</div>
      </div>
    </>
  );
}
