import { useEffect, useCallback, useMemo } from 'react';

import * as signalR from '@microsoft/signalr';

import { getAuthData } from '../helpers/authStorage';

import { COMHUB_HUB_URL } from '../constants/main';

export default function useComhubChatConnection() {
  const { accessToken } = getAuthData();

  const connection = useMemo(
    () =>
      new signalR.HubConnectionBuilder()
        .withUrl(COMHUB_HUB_URL, {
          accessTokenFactory: () => {
            return accessToken;
          },
        })
        .configureLogging(signalR.LogLevel.Information)
        .build(),
    [accessToken]
  );

  const startConnection = useCallback(async () => {
    try {
      await connection.start();
      console.log('Connected');
    } catch (error) {
      console.error(error);
    }
  }, [connection]);

  const endConnection = useCallback(async () => {
    try {
      await connection.stop();
    } catch (error) {
      console.error(error);
    }
  }, [connection]);

  useEffect(() => {
    startConnection();

    return () => {
      endConnection();
    };
  }, [startConnection, endConnection]);

  return {
    connection,
    startConnection,
    endConnection,
  };
}
