import i18n from '../i18n';

const t = i18n.t.bind(i18n);

export const CMS_ANONYMOUS_API = process.env.REACT_APP_CMS_ANONYMOUS_API;
export const JOBS_ANONYMOUS_API = process.env.REACT_APP_JOBS_ANONYMOUS_API;
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
export const CITY_IMG_URL = process.env.REACT_APP_CITY_IMG_URL;
export const JOBS_API_URL = process.env.REACT_APP_JOBS_API_URL;
export const CMS_API_URL = process.env.REACT_APP_CMS_API_URL;
export const MAP_KEY = process.env.REACT_APP_MAP_KEY;
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;
export const JOBPOST_IMG_URL = process.env.REACT_APP_JOBPOST_IMG_URL;
export const TENANT_ID = process.env.REACT_APP_TENANT_ID;
export const CHAT_HUB_URL = process.env.REACT_APP_CHAT_HUB_URL;
export const COMHUB_API_URL = process.env.REACT_APP_COMHUB_API_URL;
export const COMHUB_HUB_URL = process.env.REACT_APP_COMHUB_HUB_URL;

export const TURKISH_CITIES = [
  'Adana',
  'Adiyaman',
  'Afyonkarahi̇sar',
  'Ağri',
  'Aksaray',
  'Amasya',
  'Ankara',
  'Antalya',
  'Ardahan',
  'Artvi̇n',
  'Aydin',
  'Balikesi̇r',
  'Bartin',
  'Batman',
  'Bayburt',
  'Bi̇leci̇k',
  'Bi̇ngöl',
  'Bi̇tli̇s',
  'Bolu',
  'Burdur',
  'Bursa',
  'Çanakkale',
  'Çankiri',
  'Çorum',
  'Deni̇zli̇',
  'Di̇yarbakir',
  'Düzce',
  'Edi̇rne',
  'Elaziğ',
  'Erzi̇ncan',
  'Erzurum',
  'Eski̇şehi̇r',
  'Gazi̇antep',
  'Gi̇resun',
  'Gi̇rne',
  'Gümüşhane',
  'Hakkari̇',
  'Hatay',
  'Iğdir',
  'Isparta',
  'İstanbul',
  'İzmi̇r',
  'Kahramanmaraş',
  'Karabük',
  'Karaman',
  'Kars',
  'Kastamonu',
  'Kayseri̇',
  'Ki̇li̇s',
  'Kirikkale',
  'Kirklareli̇',
  'Kirşehi̇r',
  'Kocaeli̇',
  'Konya',
  'Kütahya',
  'Lefkoşe',
  'Mağusa',
  'Malatya',
  'Mani̇sa',
  'Mardi̇n',
  'Mersi̇n',
  'Muğla',
  'Muş',
  'Nevşehi̇r',
  'Ni̇ğde',
  'Ordu',
  'Osmani̇ye',
  'Ri̇ze',
  'Sakarya',
  'Samsun',
  'Şanliurfa',
  'Si̇i̇rt',
  'Si̇nop',
  'Şirnak',
  'Si̇vas',
  'Teki̇rdağ',
  'Tokat',
  'Trabzon',
  'Tunceli̇',
  'Uşak',
  'Van',
  'Yalova',
  'Yozgat',
  'Zonguldak',
];

export const languages = [
  {
    name: 'EN',
    iso: 'US',
    languageCode: 'en-US',
  },
  {
    name: 'TR',
    iso: 'TR',
    languageCode: 'tr-TR',
  },
];

export const countries = [
  {
    name: t('common.countries.Afghanistan'),
    iso: 'AF',
    phoneCode: '+93',
  },
  {
    name: t('common.countries.Albania'),
    iso: 'AL',
    phoneCode: '+355',
  },
  {
    name: t('common.countries.Algeria'),
    iso: 'DZ',
    phoneCode: '+213',
  },
  {
    name: t('common.countries.Andorra'),
    iso: 'AD',
    phoneCode: '+376',
  },
  {
    name: t('common.countries.Angola'),
    iso: 'AO',
    phoneCode: '+244',
  },
  {
    name: t('common.countries.Antigua and Barbuda'),
    iso: 'AG',
    phoneCode: '+1268',
  },
  {
    name: t('common.countries.Argentina'),
    iso: 'AR',
    phoneCode: '+54',
  },
  {
    name: t('common.countries.Armenia'),
    iso: 'AM',
    phoneCode: '+374',
  },
  {
    name: t('common.countries.Australia'),
    iso: 'AU',
    phoneCode: '+61',
  },
  {
    name: t('common.countries.Austria'),
    iso: 'AT',
    phoneCode: '+43',
  },
  {
    name: t('common.countries.Azerbaijan'),
    iso: 'AZ',
    phoneCode: '+994',
  },
  {
    name: t('common.countries.Bahamas'),
    iso: 'BS',
    phoneCode: '+1242',
  },
  {
    name: t('common.countries.Bahrain'),
    iso: 'BH',
    phoneCode: '+973',
  },
  {
    name: t('common.countries.Bangladesh'),
    iso: 'BD',
    phoneCode: '+880',
  },
  {
    name: t('common.countries.Barbados'),
    iso: 'BB',
    phoneCode: '+1246',
  },
  {
    name: t('common.countries.Belarus'),
    iso: 'BY',
    phoneCode: '+375',
  },
  {
    name: t('common.countries.Belgium'),
    iso: 'BE',
    phoneCode: '+32',
  },
  {
    name: t('common.countries.Belize'),
    iso: 'BZ',
    phoneCode: '+501',
  },
  {
    name: t('common.countries.Benin'),
    iso: 'BJ',
    phoneCode: '+229',
  },
  {
    name: t('common.countries.Bhutan'),
    iso: 'BT',
    phoneCode: '+975',
  },
  {
    name: t('common.countries.Bolivia'),
    iso: 'BO',
    phoneCode: '+591',
  },
  {
    name: t('common.countries.Bosnia and Herzegovina'),
    iso: 'BA',
    phoneCode: '+387',
  },
  {
    name: t('common.countries.Botswana'),
    iso: 'BW',
    phoneCode: '+267',
  },
  {
    name: t('common.countries.Brazil'),
    iso: 'BR',
    phoneCode: '+55',
  },
  {
    name: t('common.countries.Brunei'),
    iso: 'BN',
    phoneCode: '+673',
  },
  {
    name: t('common.countries.Bulgaria'),
    iso: 'BG',
    phoneCode: '+359',
  },
  {
    name: t('common.countries.Burkina Faso'),
    iso: 'BF',
    phoneCode: '+226',
  },
  {
    name: t('common.countries.Burundi'),
    iso: 'BI',
    phoneCode: '+257',
  },
  {
    name: t('common.countries.Cabo Verde'),
    iso: 'CV',
    phoneCode: '+238',
  },
  {
    name: t('common.countries.Cambodia'),
    iso: 'KH',
    phoneCode: '+855',
  },
  {
    name: t('common.countries.Cameroon'),
    iso: 'CM',
    phoneCode: '+237',
  },
  {
    name: t('common.countries.Canada'),
    iso: 'CA',
    phoneCode: '+1',
    areas: [
      204, 236, 226, 249, 250, 263, 289, 306, 343, 354, 365, 367, 368, 403, 416,
      418, 431, 437, 438, 450, 468, 474, 506, 514, 519, 548, 579, 581, 584, 587,
      604, 613, 639, 647, 672, 683, 705, 709, 742, 753, 778, 780, 782, 807, 819,
      825, 867, 873, 902, 905,
    ],
  },
  {
    name: t('common.countries.Central African Republic'),
    iso: 'CF',
    phoneCode: '+236',
  },
  {
    name: t('common.countries.Chad'),
    iso: 'TD',
    phoneCode: '+235',
  },
  {
    name: t('common.countries.Chile'),
    iso: 'CL',
    phoneCode: '+56',
  },
  {
    name: t('common.countries.China'),
    iso: 'CN',
    phoneCode: '+86',
  },
  {
    name: t('common.countries.Colombia'),
    iso: 'CO',
    phoneCode: '+57',
  },
  {
    name: t('common.countries.Comoros'),
    iso: 'KM',
    phoneCode: '+269',
  },
  {
    name: t('common.countries.Congo (Brazzaville)'),
    iso: 'CG',
    phoneCode: '+242',
  },
  {
    name: t('common.countries.Congo (Kinshasa)'),
    iso: 'CD',
    phoneCode: '+243',
  },
  {
    name: t('common.countries.Costa Rica'),
    iso: 'CR',
    phoneCode: '+506',
  },
  {
    name: t('common.countries.Croatia'),
    iso: 'HR',
    phoneCode: '+385',
  },
  {
    name: t('common.countries.Cuba'),
    iso: 'CU',
    phoneCode: '+53',
  },
  {
    name: t('common.countries.Cyprus'),
    iso: 'CY',
    phoneCode: '+357',
  },
  {
    name: t('common.countries.Czech Republic'),
    iso: 'CZ',
    phoneCode: '+420',
  },
  {
    name: t('common.countries.Denmark'),
    iso: 'DK',
    phoneCode: '+45',
  },
  {
    name: t('common.countries.Djibouti'),
    iso: 'DJ',
    phoneCode: '+253',
  },
  {
    name: t('common.countries.Dominica'),
    iso: 'DM',
    phoneCode: '+1767',
  },
  {
    name: t('common.countries.Dominican Republic'),
    iso: 'DO',
    phoneCode: '+1',
  },
  {
    name: t('common.countries.Ecuador'),
    iso: 'EC',
    phoneCode: '+593',
  },
  {
    name: t('common.countries.Egypt'),
    iso: 'EG',
    phoneCode: '+20',
  },
  {
    name: t('common.countries.El Salvador'),
    iso: 'SV',
    phoneCode: '+503',
  },
  {
    name: t('common.countries.Equatorial Guinea'),
    iso: 'GQ',
    phoneCode: '+240',
  },
  {
    name: t('common.countries.Eritrea'),
    iso: 'ER',
    phoneCode: '+291',
  },
  {
    name: t('common.countries.Estonia'),
    iso: 'EE',
    phoneCode: '+372',
  },
  {
    name: t('common.countries.Eswatini'),
    iso: 'SZ',
    phoneCode: '+268',
  },
  {
    name: t('common.countries.Ethiopia'),
    iso: 'ET',
    phoneCode: '+251',
  },
  {
    name: t('common.countries.Fiji'),
    iso: 'FJ',
    phoneCode: '+679',
  },
  {
    name: t('common.countries.Finland'),
    iso: 'FI',
    phoneCode: '+358',
  },
  {
    name: t('common.countries.France'),
    iso: 'FR',
    phoneCode: '+33',
  },
  {
    name: t('common.countries.Gabon'),
    iso: 'GA',
    phoneCode: '+241',
  },
  {
    name: t('common.countries.Gambia'),
    iso: 'GM',
    phoneCode: '+220',
  },
  {
    name: t('common.countries.Georgia'),
    iso: 'GE',
    phoneCode: '+995',
  },
  {
    name: t('common.countries.Germany'),
    iso: 'DE',
    phoneCode: '+49',
  },
  {
    name: t('common.countries.Ghana'),
    iso: 'GH',
    phoneCode: '+233',
  },
  {
    name: t('common.countries.Greece'),
    iso: 'GR',
    phoneCode: '+30',
  },
  {
    name: t('common.countries.Grenada'),
    iso: 'GD',
    phoneCode: '+1473',
  },
  {
    name: t('common.countries.Guatemala'),
    iso: 'GT',
    phoneCode: '+502',
  },
  {
    name: t('common.countries.Guinea'),
    iso: 'GN',
    phoneCode: '+224',
  },
  {
    name: t('common.countries.Guinea-Bissau'),
    iso: 'GW',
    phoneCode: '+245',
  },
  {
    name: t('common.countries.Guyana'),
    iso: 'GY',
    phoneCode: '+592',
  },
  {
    name: t('common.countries.Haiti'),
    iso: 'HT',
    phoneCode: '+509',
  },
  {
    name: t('common.countries.Honduras'),
    iso: 'HN',
    phoneCode: '+504',
  },
  {
    name: t('common.countries.Hungary'),
    iso: 'HU',
    phoneCode: '+36',
  },
  {
    name: t('common.countries.Iceland'),
    iso: 'IS',
    phoneCode: '+354',
  },
  {
    name: t('common.countries.India'),
    iso: 'IN',
    phoneCode: '+91',
  },
  {
    name: t('common.countries.Indonesia'),
    iso: 'ID',
    phoneCode: '+62',
  },
  {
    name: t('common.countries.Iran'),
    iso: 'IR',
    phoneCode: '+98',
  },
  {
    name: t('common.countries.Iraq'),
    iso: 'IQ',
    phoneCode: '+964',
  },
  {
    name: t('common.countries.Ireland'),
    iso: 'IE',
    phoneCode: '+353',
  },
  {
    name: t('common.countries.Israel'),
    iso: 'IL',
    phoneCode: '+972',
  },
  {
    name: t('common.countries.Italy'),
    iso: 'IT',
    phoneCode: '+39',
  },
  {
    name: t('common.countries.Jamaica'),
    iso: 'JM',
    phoneCode: '+1876',
  },
  {
    name: t('common.countries.Japan'),
    iso: 'JP',
    phoneCode: '+81',
  },
  {
    name: t('common.countries.Jordan'),
    iso: 'JO',
    phoneCode: '+962',
  },
  {
    name: t('common.countries.Kazakhstan'),
    iso: 'KZ',
    phoneCode: '+7',
  },
  {
    name: t('common.countries.Kenya'),
    iso: 'KE',
    phoneCode: '+254',
  },
  {
    name: t('common.countries.Kiribati'),
    iso: 'KI',
    phoneCode: '+686',
  },
  {
    name: t('common.countries.Korea (North)'),
    iso: 'KP',
    phoneCode: '+850',
  },
  {
    name: t('common.countries.Korea (South)'),
    iso: 'KR',
    phoneCode: '+82',
  },
  {
    name: t('common.countries.Kosovo'),
    iso: 'XK',
    phoneCode: '+383',
  },
  {
    name: t('common.countries.Kuwait'),
    iso: 'KW',
    phoneCode: '+965',
  },
  {
    name: t('common.countries.Kyrgyzstan'),
    iso: 'KG',
    phoneCode: '+996',
  },
  {
    name: t('common.countries.Laos'),
    iso: 'LA',
    phoneCode: '+856',
  },
  {
    name: t('common.countries.Latvia'),
    iso: 'LV',
    phoneCode: '+371',
  },
  {
    name: t('common.countries.Lebanon'),
    iso: 'LB',
    phoneCode: '+961',
  },
  {
    name: t('common.countries.Lesotho'),
    iso: 'LS',
    phoneCode: '+266',
  },
  {
    name: t('common.countries.Liberia'),
    iso: 'LR',
    phoneCode: '+231',
  },
  {
    name: t('common.countries.Libya'),
    iso: 'LY',
    phoneCode: '+218',
  },
  {
    name: t('common.countries.Liechtenstein'),
    iso: 'LI',
    phoneCode: '+423',
  },
  {
    name: t('common.countries.Lithuania'),
    iso: 'LT',
    phoneCode: '+370',
  },
  {
    name: t('common.countries.Luxembourg'),
    iso: 'LU',
    phoneCode: '+352',
  },
  {
    name: t('common.countries.Madagascar'),
    iso: 'MG',
    phoneCode: '+261',
  },
  {
    name: t('common.countries.Malawi'),
    iso: 'MW',
    phoneCode: '+265',
  },
  {
    name: t('common.countries.Malaysia'),
    iso: 'MY',
    phoneCode: '+60',
  },
  {
    name: t('common.countries.Maldives'),
    iso: 'MV',
    phoneCode: '+960',
  },
  {
    name: t('common.countries.Mali'),
    iso: 'ML',
    phoneCode: '+223',
  },
  {
    name: t('common.countries.Malta'),
    iso: 'MT',
    phoneCode: '+356',
  },
  {
    name: t('common.countries.Marshall Islands'),
    iso: 'MH',
    phoneCode: '+692',
  },
  {
    name: t('common.countries.Mauritania'),
    iso: 'MR',
    phoneCode: '+222',
  },
  {
    name: t('common.countries.Mauritius'),
    iso: 'MU',
    phoneCode: '+230',
  },
  {
    name: t('common.countries.Mexico'),
    iso: 'MX',
    phoneCode: '+52',
  },
  {
    name: t('common.countries.Micronesia'),
    iso: 'FM',
    phoneCode: '+691',
  },
  {
    name: t('common.countries.Moldova'),
    iso: 'MD',
    phoneCode: '+373',
  },
  {
    name: t('common.countries.Monaco'),
    iso: 'MC',
    phoneCode: '+377',
  },
  {
    name: t('common.countries.Mongolia'),
    iso: 'MN',
    phoneCode: '+976',
  },
  {
    name: t('common.countries.Montenegro'),
    iso: 'ME',
    phoneCode: '+382',
  },
  {
    name: t('common.countries.Morocco'),
    iso: 'MA',
    phoneCode: '+212',
  },
  {
    name: t('common.countries.Mozambique'),
    iso: 'MZ',
    phoneCode: '+258',
  },
  {
    name: t('common.countries.Myanmar'),
    iso: 'MM',
    phoneCode: '+95',
  },
  {
    name: t('common.countries.Namibia'),
    iso: 'NA',
    phoneCode: '+264',
  },
  {
    name: t('common.countries.Nauru'),
    iso: 'NR',
    phoneCode: '+674',
  },
  {
    name: t('common.countries.Nepal'),
    iso: 'NP',
    phoneCode: '+977',
  },
  {
    name: t('common.countries.Netherlands'),
    iso: 'NL',
    phoneCode: '+31',
  },
  {
    name: t('common.countries.New Zealand'),
    iso: 'NZ',
    phoneCode: '+64',
  },
  {
    name: t('common.countries.Nicaragua'),
    iso: 'NI',
    phoneCode: '+505',
  },
  {
    name: t('common.countries.Niger'),
    iso: 'NE',
    phoneCode: '+227',
  },
  {
    name: t('common.countries.Nigeria'),
    iso: 'NG',
    phoneCode: '+234',
  },
  {
    name: t('common.countries.North Macedonia'),
    iso: 'MK',
    phoneCode: '+389',
  },
  {
    name: t('common.countries.Norway'),
    iso: 'NO',
    phoneCode: '+47',
  },
  {
    name: t('common.countries.Oman'),
    iso: 'OM',
    phoneCode: '+968',
  },
  {
    name: t('common.countries.Pakistan'),
    iso: 'PK',
    phoneCode: '+92',
  },
  {
    name: t('common.countries.Palau'),
    iso: 'PW',
    phoneCode: '+680',
  },
  {
    name: t('common.countries.Palestine'),
    iso: 'PS',
    phoneCode: '+970',
  },
  {
    name: t('common.countries.Panama'),
    iso: 'PA',
    phoneCode: '+507',
  },
  {
    name: t('common.countries.Papua New Guinea'),
    iso: 'PG',
    phoneCode: '+675',
  },
  {
    name: t('common.countries.Paraguay'),
    iso: 'PY',
    phoneCode: '+595',
  },
  {
    name: t('common.countries.Peru'),
    iso: 'PE',
    phoneCode: '+51',
  },
  {
    name: t('common.countries.Philippines'),
    iso: 'PH',
    phoneCode: '+63',
  },
  {
    name: t('common.countries.Poland'),
    iso: 'PL',
    phoneCode: '+48',
  },
  {
    name: t('common.countries.Portugal'),
    iso: 'PT',
    phoneCode: '+351',
  },
  {
    name: t('common.countries.Qatar'),
    iso: 'QA',
    phoneCode: '+974',
  },
  {
    name: t('common.countries.Romania'),
    iso: 'RO',
    phoneCode: '+40',
  },
  {
    name: t('common.countries.Russia'),
    iso: 'RU',
    phoneCode: '+7',
    areas: [
      495, 499, 812, 343, 831, 846, 818, 835, 491, 836, 843, 844, 815, 473, 816,
      492, 351, 472, 381, 472, 863, 484, 341, 346, 427, 487, 342, 395, 487, 343,
      818, 395, 818, 491, 842, 867, 818, 867, 365, 349, 818, 346, 818, 472, 863,
      486, 818, 492, 848, 818, 472, 365, 818, 471, 818, 818, 814, 818, 872, 865,
      818, 841, 815, 346, 842, 491, 471, 343, 345, 818, 471, 495, 471, 471, 818,
      343, 347, 815, 345, 472, 818, 471, 345, 472, 817, 347, 495, 471, 865, 815,
      347, 347, 345, 472, 471, 395,
    ],
  },
  {
    name: t('common.countries.Rwanda'),
    iso: 'RW',
    phoneCode: '+250',
  },
  {
    name: t('common.countries.Saint Kitts and Nevis'),
    iso: 'KN',
    phoneCode: '+1869',
  },
  {
    name: t('common.countries.Saint Lucia'),
    iso: 'LC',
    phoneCode: '+1758',
  },
  {
    name: t('common.countries.Saint Vincent and the Grenadines'),
    iso: 'VC',
    phoneCode: '+1784',
  },
  {
    name: t('common.countries.Samoa'),
    iso: 'WS',
    phoneCode: '+685',
  },
  {
    name: t('common.countries.San Marino'),
    iso: 'SM',
    phoneCode: '+378',
  },
  {
    name: t('common.countries.Sao Tome and Principe'),
    iso: 'ST',
    phoneCode: '+239',
  },
  {
    name: t('common.countries.Saudi Arabia'),
    iso: 'SA',
    phoneCode: '+966',
  },
  {
    name: t('common.countries.Senegal'),
    iso: 'SN',
    phoneCode: '+221',
  },
  {
    name: t('common.countries.Serbia'),
    iso: 'RS',
    phoneCode: '+381',
  },
  {
    name: t('common.countries.Seychelles'),
    iso: 'SC',
    phoneCode: '+248',
  },
  {
    name: t('common.countries.Sierra Leone'),
    iso: 'SL',
    phoneCode: '+232',
  },
  {
    name: t('common.countries.Singapore'),
    iso: 'SG',
    phoneCode: '+65',
  },
  {
    name: t('common.countries.Slovakia'),
    iso: 'SK',
    phoneCode: '+421',
  },
  {
    name: t('common.countries.Slovenia'),
    iso: 'SI',
    phoneCode: '+386',
  },
  {
    name: t('common.countries.Solomon Islands'),
    iso: 'SB',
    phoneCode: '+677',
  },
  {
    name: t('common.countries.Somalia'),
    iso: 'SO',
    phoneCode: '+252',
  },
  {
    name: t('common.countries.South Africa'),
    iso: 'ZA',
    phoneCode: '+27',
  },
  {
    name: t('common.countries.South Sudan'),
    iso: 'SS',
    phoneCode: '+211',
  },
  {
    name: t('common.countries.Spain'),
    iso: 'ES',
    phoneCode: '+34',
  },
  {
    name: t('common.countries.Sri Lanka'),
    iso: 'LK',
    phoneCode: '+94',
  },
  {
    name: t('common.countries.Sudan'),
    iso: 'SD',
    phoneCode: '+249',
  },
  {
    name: t('common.countries.Suriname'),
    iso: 'SR',
    phoneCode: '+597',
  },
  {
    name: t('common.countries.Sweden'),
    iso: 'SE',
    phoneCode: '+46',
  },
  {
    name: t('common.countries.Switzerland'),
    iso: 'CH',
    phoneCode: '+41',
  },
  {
    name: t('common.countries.Syria'),
    iso: 'SY',
    phoneCode: '+963',
  },
  {
    name: t('common.countries.Taiwan'),
    iso: 'TW',
    phoneCode: '+886',
  },
  {
    name: t('common.countries.Tajikistan'),
    iso: 'TJ',
    phoneCode: '+992',
  },
  {
    name: t('common.countries.Tanzania'),
    iso: 'TZ',
    phoneCode: '+255',
  },
  {
    name: t('common.countries.Thailand'),
    iso: 'TH',
    phoneCode: '+66',
  },
  {
    name: t('common.countries.Timor-Leste'),
    iso: 'TL',
    phoneCode: '+670',
  },
  {
    name: t('common.countries.Togo'),
    iso: 'TG',
    phoneCode: '+228',
  },
  {
    name: t('common.countries.Tonga'),
    iso: 'TO',
    phoneCode: '+676',
  },
  {
    name: t('common.countries.Trinidad and Tobago'),
    iso: 'TT',
    phoneCode: '+1868',
  },
  {
    name: t('common.countries.Tunisia'),
    iso: 'TN',
    phoneCode: '+216',
  },
  {
    name: t('common.countries.Turkey'),
    iso: 'TR',
    phoneCode: '+90',
  },
  {
    name: t('common.countries.Turkmenistan'),
    iso: 'TM',
    phoneCode: '+993',
  },
  {
    name: t('common.countries.Tuvalu'),
    iso: 'TV',
    phoneCode: '+688',
  },
  {
    name: t('common.countries.Uganda'),
    iso: 'UG',
    phoneCode: '+256',
  },
  {
    name: t('common.countries.Ukraine'),
    iso: 'UA',
    phoneCode: '+380',
  },
  {
    name: t('common.countries.United Arab Emirates'),
    iso: 'AE',
    phoneCode: '+971',
  },
  {
    name: t('common.countries.United Kingdom'),
    iso: 'GB',
    phoneCode: '+44',
  },
  {
    name: t('common.countries.United States of America'),
    iso: 'US',
    phoneCode: '+1',
    areas: [
      228, 601, 662, 769, 670, 405, 539, 580, 918, 302, 218, 320, 507, 612, 651,
      763, 952, 217, 224, 309, 312, 331, 618, 630, 708, 773, 779, 815, 847, 872,
      479, 501, 870, 505, 575, 219, 260, 317, 574, 765, 812, 240, 301, 410, 443,
      667, 225, 318, 337, 504, 985, 208, 307, 423, 615, 731, 865, 901, 931, 480,
      520, 602, 623, 928, 319, 515, 563, 641, 712, 231, 248, 269, 313, 517, 586,
      616, 734, 810, 906, 947, 989, 316, 620, 785, 913, 385, 435, 801, 684, 458,
      503, 541, 971, 203, 475, 860, 406, 209, 213, 310, 323, 408, 415, 424, 442,
      510, 530, 559, 562, 619, 626, 650, 657, 661, 669, 707, 714, 747, 760, 805,
      818, 831, 858, 909, 916, 925, 949, 951, 339, 351, 413, 508, 617, 774, 781,
      857, 978, 787, 939, 803, 843, 864, 603, 262, 414, 534, 608, 715, 920, 802,
      229, 404, 470, 478, 678, 706, 762, 770, 912, 701, 215, 267, 272, 412, 484,
      570, 610, 717, 724, 814, 878, 304, 681, 239, 305, 321, 352, 386, 407, 561,
      727, 754, 772, 786, 813, 850, 863, 904, 941, 954, 808, 270, 502, 606, 859,
      907, 308, 402, 531, 314, 417, 573, 636, 660, 816, 216, 234, 330, 419, 440,
      513, 567, 614, 740, 937, 205, 251, 256, 334, 938, 401, 202, 340, 605, 303,
      719, 720, 970, 201, 551, 609, 732, 848, 856, 862, 908, 973, 276, 434, 540,
      571, 703, 757, 804, 671, 206, 253, 360, 425, 509, 252, 336, 704, 828, 910,
      919, 980, 984, 212, 315, 347, 516, 518, 585, 607, 631, 646, 716, 718, 845,
      914, 917, 929, 210, 214, 254, 281, 325, 346, 361, 409, 430, 432, 469, 512,
      682, 713, 737, 806, 817, 830, 832, 903, 915, 936, 940, 956, 972, 979, 702,
      725, 775, 207,
    ],
  },
  {
    name: t('common.countries.Uruguay'),
    iso: 'UY',
    phoneCode: '+598',
  },
  {
    name: t('common.countries.Uzbekistan'),
    iso: 'UZ',
    phoneCode: '+998',
  },
  {
    name: t('common.countries.Vanuatu'),
    iso: 'VU',
    phoneCode: '+678',
  },
  {
    name: t('common.countries.Vatican City'),
    iso: 'VA',
    phoneCode: '+379',
  },
  {
    name: t('common.countries.Venezuela'),
    iso: 'VE',
    phoneCode: '+58',
  },
  {
    name: t('common.countries.Vietnam'),
    iso: 'VN',
    phoneCode: '+84',
  },
  {
    name: t('common.countries.Yemen'),
    iso: 'YE',
    phoneCode: '+967',
  },
  {
    name: t('common.countries.Zambia'),
    iso: 'ZM',
    phoneCode: '+260',
  },
  {
    name: t('common.countries.Zimbabwe'),
    iso: 'ZW',
    phoneCode: '+263',
  },
];
