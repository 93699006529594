import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import Textarea from '../../../../Textarea';

import classes from './styles.module.scss';

export default function ReplyBox({
  newMessage,
  setNewMessage,
  isOthersTyping,
}) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className={classes.ReplyBox}>
      <h4>
        {t(
          'components.MyDashboard.JobApplicationInfo.Messages.ReplyBox.myReply'
        )}
      </h4>
      <Textarea
        height={isTabletOrMobile ? 156 : 105}
        placeholder={t(
          'components.MyDashboard.JobApplicationInfo.Messages.ReplyBox.enterYourMessageHere'
        )}
        maxLength={2000}
        value={newMessage}
        setValue={setNewMessage}
        isOthersTyping={isOthersTyping}
      />
    </div>
  );
}
