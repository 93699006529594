import React, { useState, useEffect, useContext, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from '@tanstack/react-query';

import Message from './Message';
import BlackButton from '../BlackButton';
import Button from '../../../Button';
import ReplyBox from './ReplyBox';
import { UiContext } from '../../../../context/UiContext';
import PublicService from '../../../../services/PublicService';
import UserService from '../../../../services/UserService';
import backArrowIcon from '../../../../assets/images/dashboard/back-arrow.svg';
import classes from './styles.module.scss';
import { getAuthData } from '../../../../helpers/authStorage';

export default function Messages({
  goBack,
  refetch,
  connection,
  reloadMessageCounters,
  jobApplicationId,
  fromNotification,
}) {
  const [newMessage, setNewMessage] = useState('');

  const messagesContainerRef = useRef();
  const { userId } = getAuthData();
  const { setIsFetching } = useContext(UiContext);

  const queryClient = useQueryClient();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const [userData, setUserData] = useState({});
  const [messages, setMessages] = useState([]);
  const [thread, setThread] = useState({});
  const [isTyping, setIsTyping] = useState(false);
  const [isOthersTyping, setIsOthersTyping] = useState(null);

  let typingTimeout;

  const sendMessage = async () => {
    try {
      if (newMessage.trim()) {
        setIsFetching(true);
        await PublicService.createJobApplicationMessage({
          jobApplicationId,
          content: newMessage,
        });
        setNewMessage('');

        // Refetch messages
        const updatedMessages = await PublicService.getJobApplicationMessages({
          jobApplicationId,
        });
        if (Array.isArray(updatedMessages)) {
          setMessages(updatedMessages);
        } else {
          console.error(
            'Unexpected response format during refresh:',
            updatedMessages
          );
        }

        // Smoothly scroll to the bottom of the messages container
        const messagesContainer = messagesContainerRef.current;
        if (messagesContainer) {
          messagesContainer.scrollTo({
            top: messagesContainer.scrollHeight,
            behavior: 'smooth',
          });
        }

        // If the user is coming from a notification, we need to refetch notifications to display the sent message
        if (fromNotification) {
          await queryClient.invalidateQueries({ queryKey: ['notifications'] });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const joinGroup = (threadId) => {
    if (connection) {
      const groupName = `${'group'}-${threadId}`;
      console.log(`Joining group: ${groupName}`);
      connection
        .invoke('JoinGroup', groupName)
        .then(() => console.log(`Joined group: ${groupName}`))
        .catch((err) => console.error('Error joining group:', err));
    }
  };

  useEffect(() => {
    console.log(`Fetching messages for jobApp.id: ${jobApplicationId}`); // Debugging log

    if (thread.id == null && jobApplicationId != null) {
      console.log(`Fetching thread for jobApp.id: ${jobApplicationId}`); // Debugging log
      PublicService.getJobApplicationThread({
        jobApplicationId,
      })
        .then((response) => {
          console.log('thread: ', response);
          setThread(response);
          joinGroup(response?.id);
        })
        .catch((error) => {
          console.error(
            `Error fetching thread counter for jobApp.id ${jobApplicationId}:`,
            error
          );
        });
    }

    PublicService.getJobApplicationMessages({
      jobApplicationId,
    })
      .then((response) => {
        setMessages(response);
        reloadMessageCounters();
      })
      .catch((error) => {
        console.error(
          `Error fetching messages counter for jobApp.id ${jobApplicationId}:`,
          error
        );
      });

    UserService.getMe()
      .then((response) => {
        setUserData(response);
      })
      .catch((error) => {
        console.error(
          `Error fetching thread counter for jobApp.id ${jobApplicationId}:`,
          error
        );
      });
  }, [jobApplicationId]);

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;

    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]);

  const handleTyping = () => {
    console.log('User is typing', userData);
    const groupName = `${'group'}-${thread.id}`;

    if (!isTyping) {
      setIsTyping(true);
      connection?.send(
        'Typing',
        groupName,
        userData?.userProfile?.fullName ?? ''
      );
    }

    if (typingTimeout) clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      setIsTyping(false);
      connection?.send(
        'StoppedTyping',
        groupName,
        userData?.userProfile?.fullName ?? ''
      );
    }, 3000);
  };

  useEffect(() => {
    if ((newMessage, newMessage.length > 0)) {
      handleTyping();
    }
  }, [newMessage]);

  const markMessageAsRead = async (messageId) => {
    try {
      await PublicService.markMessageAsRead({ messageId });
      reloadMessageCounters();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (connection) {
      connection.on('NewMessage', (message) => {
        if (+userId !== message.authorId) {
          setMessages((prevMessages) => [...prevMessages, message]);
          markMessageAsRead(message.id);
        }
      });

      connection.on('Typing', (user) => {
        console.log('Typing: ', user?.userName);
        setIsOthersTyping(user?.userName);
      });

      connection.on('StoppedTyping', () => {
        setIsOthersTyping(null);
      });
    }

    return () => {
      connection?.off?.('NewMessage');
      connection?.off?.('Typing');
      connection?.off?.('StoppedTyping');
    };
  }, [connection]);

  return (
    <div className={classes.Messages}>
      <div className={classes.header}>
        <button type="button" className={classes.backButton} onClick={goBack}>
          <img src={backArrowIcon} alt="Back" />
        </button>
      </div>
      <div className={classes.messagesContainer} ref={messagesContainerRef}>
        <ul className={classes.messagesList}>
          {messages
            ?.filter((message) => message?.feedback === null)
            ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((message) => (
              <Message key={message.id} message={message} />
            ))}
        </ul>
        {/* Typing Indicator */}

        <ReplyBox
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          refetch={refetch}
          isOthersTyping={isOthersTyping}
        />
      </div>
      <div className={classes.buttons}>
        <BlackButton
          onClick={() => setNewMessage('')}
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
        >
          Clear
        </BlackButton>
        <Button
          width={isTabletOrMobile ? '' : 300}
          height={isTabletOrMobile ? 54 : 64}
          style={{ fontSize: 24, fontWeight: 500 }}
          onClick={sendMessage}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
